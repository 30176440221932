import { graphql } from 'relay-runtime';

export const UrlMetaDataFragment = graphql`
  fragment UrlMetaData_queryFragment on Query {
    urlMetaData(url: $url) {
      title
      description
      facebookImageUrl
      twitterImageUrl
      canonicalUrl
      openGraphImageAltText
      updatedAt
      openGraphImageDimensions {
        width
        height
      }
    }
  }
`;
