/* eslint-disable @contra/enforce-stitches-tokens */
import {
  type UrlMetaData_queryFragment$data,
  type UrlMetaData_queryFragment$key,
} from '@/__generated__/relay/UrlMetaData_queryFragment.graphql.js';
import { type UrlMetadataQuery$data } from '@/__generated__/relay/UrlMetadataQuery.graphql.js';
import { UrlMetaDataFragment } from '@/documents/fragments/UrlMetaData.js';
import { useIPFragment } from '@/hooks/useIPFragment.js';
import Head from 'next/head';

const MetaTags = ({
  isCustomDomain,
  urlMetaData,
}: {
  readonly isCustomDomain: boolean;
  readonly urlMetaData:
    | UrlMetaData_queryFragment$data['urlMetaData']
    | UrlMetadataQuery$data['urlMetaData'];
}) => {
  const ogUrlWithUpdatedAt =
    urlMetaData.canonicalUrl +
    (urlMetaData.updatedAt ? `?updated_time=${urlMetaData.updatedAt}` : '');

  return (
    <Head>
      <title>{urlMetaData?.title}</title>
      <meta
        content="width=device-width, initial-scale=1.0"
        name="viewport"
      />
      {isCustomDomain ? null : (
        <meta
          content="noindex"
          name="robots"
        />
      )}
      <link
        href={urlMetaData?.canonicalUrl}
        rel="canonical"
      />
      {/* Open Graph Tags */}
      <meta
        content={urlMetaData?.description}
        name="description"
      />
      <meta
        content={urlMetaData.openGraphImageAltText}
        name="og:image:alt"
      />
      <meta
        content={String(urlMetaData.openGraphImageDimensions.width)}
        property="og:image:width"
      />
      <meta
        content={String(urlMetaData.openGraphImageDimensions.height)}
        property="og:image:height"
      />
      {urlMetaData.updatedAt ? (
        <meta
          content={String(urlMetaData.updatedAt)}
          property="og:updated_time"
        />
      ) : null}

      {/* Facebook Meta Tags */}
      <meta
        content={ogUrlWithUpdatedAt}
        property="og:url"
      />
      <meta
        content="website"
        property="og:type"
      />
      <meta
        content={urlMetaData?.title}
        property="og:title"
      />
      <meta
        content={urlMetaData?.description}
        property="og:description"
      />
      <meta
        content={urlMetaData?.facebookImageUrl}
        name="image"
        property="og:image"
      />
      <meta
        content="2383064805289505"
        property="fb:app_id"
      />

      {/* Twitter Meta Tags */}
      <meta
        content="summary_large_image"
        name="twitter:card"
      />
      <meta
        content={ogUrlWithUpdatedAt}
        name="twitter:url"
      />
      <meta
        content={urlMetaData?.title}
        name="twitter:title"
      />
      <meta
        content={urlMetaData?.twitterImageUrl}
        name="twitter:image"
      />
      <meta
        content={urlMetaData?.description}
        name="twitter:description"
      />
      <meta
        content="@contra"
        name="twitter:site"
      />
    </Head>
  );
};

export const PageHeadFragment = ({
  isCustomDomain,
  queryNode,
}: {
  readonly isCustomDomain: boolean;
  readonly queryNode: UrlMetaData_queryFragment$key;
}) => {
  const { urlMetaData } = useIPFragment(UrlMetaDataFragment, queryNode);
  return urlMetaData ? (
    <MetaTags
      isCustomDomain={isCustomDomain}
      urlMetaData={urlMetaData}
    />
  ) : null;
};
