import { type TemplatesRecord } from '@/templates/types.js';
import dynamic from 'next/dynamic';

export const templateRegistry: TemplatesRecord = {
  amsterdam: {
    Home: dynamic(
      async () =>
        await import('./amsterdam/pages/Home/Home.js').then(
          (module) => module.Home,
        ),
      {
        ssr: false,
      },
    ),
    MiniTemplateCard: dynamic(
      async () =>
        await import(
          './amsterdam/components/MiniTemplateCard/MiniTemplateCard.js'
        ).then((module) => module.MiniTemplateCard),
      { ssr: false },
    ),
    OG: dynamic(
      async () =>
        await import('./amsterdam/pages/OG/OG.js').then((module) => module.OG),
      {
        ssr: false,
      },
    ),
    Project: dynamic(
      async () =>
        await import('./amsterdam/pages/Project/Project.js').then(
          (module) => module.Project,
        ),
      {
        ssr: false,
      },
    ),
    Service: dynamic(
      async () =>
        await import('./amsterdam/pages/Service/Service.js').then(
          (module) => module.Service,
        ),
      {
        ssr: false,
      },
    ),
  },
  barcelona: {
    Home: dynamic(
      async () =>
        await import('./barcelona/pages/Home/Home.js').then(
          (module) => module.Home,
        ),
      {
        ssr: false,
      },
    ),
    MiniTemplateCard: dynamic(
      async () =>
        await import(
          './barcelona/components/MiniTemplateCard/MiniTemplateCard.js'
        ).then((module) => module.MiniTemplateCard),
      { ssr: false },
    ),
    OG: dynamic(
      async () =>
        await import('./barcelona/pages/OG/OG.js').then((module) => module.OG),
      {
        ssr: false,
      },
    ),
    Project: dynamic(
      async () =>
        await import('./barcelona/pages/Project/Project.js').then(
          (module) => module.Project,
        ),
      {
        ssr: false,
      },
    ),
    Service: dynamic(
      async () =>
        await import('./barcelona/pages/Service/Service.js').then(
          (module) => module.Service,
        ),
      {
        ssr: false,
      },
    ),
  },
  capeTown: {
    Home: dynamic(
      async () =>
        await import('./capeTown/pages/Home/Home.js').then(
          (module) => module.Home,
        ),
      {
        ssr: false,
      },
    ),
    MiniTemplateCard: dynamic(
      async () =>
        await import(
          './capeTown/components/MiniTemplateCard/MiniTemplateCard.js'
        ).then((module) => module.MiniTemplateCard),
      { ssr: false },
    ),
    OG: dynamic(
      async () =>
        await import('./capeTown/pages/OG/OG.js').then((module) => module.OG),
      {
        ssr: false,
      },
    ),
    Project: dynamic(
      async () =>
        await import('./capeTown/pages/Project/Project.js').then(
          (module) => module.Project,
        ),
      {
        ssr: false,
      },
    ),
    Service: dynamic(
      async () =>
        await import('./capeTown/pages/Service/Service.js').then(
          (module) => module.Service,
        ),
      {
        ssr: false,
      },
    ),
  },
  london: {
    Home: dynamic(
      async () =>
        await import('./london/pages/Home/Home.js').then(
          (module) => module.Home,
        ),
      {
        ssr: false,
      },
    ),
    MiniTemplateCard: dynamic(
      async () =>
        await import(
          './london/components/MiniTemplateCard/MiniTemplateCard.js'
        ).then((module) => module.MiniTemplateCard),
      { ssr: false },
    ),
    OG: dynamic(
      async () =>
        await import('./london/pages/OG/OG.js').then((module) => module.OG),
      {
        ssr: false,
      },
    ),
    Project: dynamic(
      async () =>
        await import('./london/pages/Project/Project.js').then(
          (module) => module.Project,
        ),
      {
        ssr: false,
      },
    ),
    Service: dynamic(
      async () =>
        await import('./london/pages/Service/Service.js').then(
          (module) => module.Service,
        ),
      {
        ssr: false,
      },
    ),
  },
  losAngeles: {
    Home: dynamic(
      async () =>
        await import('./losAngeles/pages/Home/Home.js').then(
          (module) => module.Home,
        ),
      {
        ssr: false,
      },
    ),
    MiniTemplateCard: dynamic(
      async () =>
        await import(
          './losAngeles/components/MiniTemplateCard/MiniTemplateCard.js'
        ).then((module) => module.MiniTemplateCard),
      { ssr: false },
    ),
    OG: dynamic(
      async () =>
        await import('./losAngeles/pages/OG/OG.js').then((module) => module.OG),
      {
        ssr: false,
      },
    ),
    Project: dynamic(
      async () =>
        await import('./losAngeles/pages/Project/Project.js').then(
          (module) => module.Project,
        ),
      {
        ssr: false,
      },
    ),
    Service: dynamic(
      async () =>
        await import('./losAngeles/pages/Service/Service.js').then(
          (module) => module.Service,
        ),
      {
        ssr: false,
      },
    ),
  },
  madrid: {
    Home: dynamic(
      async () =>
        await import('./madrid/pages/Home/Home.js').then(
          (module) => module.Home,
        ),
      {
        ssr: false,
      },
    ),
    MiniTemplateCard: dynamic(
      async () =>
        await import(
          './madrid/components/MiniTemplateCard/MiniTemplateCard.js'
        ).then((module) => module.MiniTemplateCard),
      { ssr: false },
    ),
    OG: dynamic(
      async () =>
        await import('./madrid/pages/OG/OG.js').then((module) => module.OG),
      {
        ssr: false,
      },
    ),
    Project: dynamic(
      async () =>
        await import('./madrid/pages/Project/Project.js').then(
          (module) => module.Project,
        ),
      {
        ssr: false,
      },
    ),
    Service: dynamic(
      async () =>
        await import('./madrid/pages/Service/Service.js').then(
          (module) => module.Service,
        ),
      {
        ssr: false,
      },
    ),
  },
  paris: {
    Home: dynamic(
      async () =>
        await import('./paris/pages/Home/Home.js').then(
          (module) => module.Home,
        ),
      {
        ssr: false,
      },
    ),
    MiniTemplateCard: dynamic(
      async () =>
        await import(
          './paris/components/MiniTemplateCard/MiniTemplateCard.js'
        ).then((module) => module.MiniTemplateCard),
      { ssr: false },
    ),
    OG: dynamic(
      async () =>
        await import('./paris/pages/OG/OG.js').then((module) => module.OG),
      {
        ssr: false,
      },
    ),
    Project: dynamic(
      async () =>
        await import('./paris/pages/Project/Project.js').then(
          (module) => module.Project,
        ),
      {
        ssr: false,
      },
    ),
    Service: dynamic(
      async () =>
        await import('./paris/pages/Service/Service.js').then(
          (module) => module.Service,
        ),
      {
        ssr: false,
      },
    ),
  },
  saoPaulo: {
    Home: dynamic(
      async () =>
        await import('./saoPaulo/pages/Home/Home.js').then(
          (module) => module.Home,
        ),
      {
        ssr: false,
      },
    ),
    MiniTemplateCard: dynamic(
      async () =>
        await import(
          './saoPaulo/components/MiniTemplateCard/MiniTemplateCard.js'
        ).then((module) => module.MiniTemplateCard),
      { ssr: false },
    ),
    OG: dynamic(
      async () =>
        await import('./saoPaulo/pages/OG/OG.js').then((module) => module.OG),
      {
        ssr: false,
      },
    ),
    Project: dynamic(
      async () =>
        await import('./saoPaulo/pages/Project/Project.js').then(
          (module) => module.Project,
        ),
      {
        ssr: false,
      },
    ),
    Service: dynamic(
      async () =>
        await import('./saoPaulo/pages/Service/Service.js').then(
          (module) => module.Service,
        ),
      {
        ssr: false,
      },
    ),
  },
  sydney: {
    Home: dynamic(
      async () =>
        await import('./sydney/pages/Home/Home.js').then(
          (module) => module.Home,
        ),
      {
        ssr: false,
      },
    ),
    MiniTemplateCard: dynamic(
      async () =>
        await import(
          './sydney/components/MiniTemplateCard/MiniTemplateCard.js'
        ).then((module) => module.MiniTemplateCard),
      { ssr: false },
    ),
    OG: dynamic(
      async () =>
        await import('./sydney/pages/OG/OG.js').then((module) => module.OG),
      {
        ssr: false,
      },
    ),
    Project: dynamic(
      async () =>
        await import('./sydney/pages/Project/Project.js').then(
          (module) => module.Project,
        ),
      {
        ssr: false,
      },
    ),
    Service: dynamic(
      async () =>
        await import('./sydney/pages/Service/Service.js').then(
          (module) => module.Service,
        ),
      {
        ssr: false,
      },
    ),
  },
  tokyo: {
    Home: dynamic(
      async () =>
        await import('./tokyo/pages/Home/Home.js').then(
          (module) => module.Home,
        ),
      {
        ssr: false,
      },
    ),
    MiniTemplateCard: dynamic(
      async () =>
        await import(
          './tokyo/components/MiniTemplateCard/MiniTemplateCard.js'
        ).then((module) => module.MiniTemplateCard),
      { ssr: false },
    ),
    OG: dynamic(
      async () =>
        await import('./tokyo/pages/OG/OG.js').then((module) => module.OG),
      {
        ssr: false,
      },
    ),
    Project: dynamic(
      async () =>
        await import('./tokyo/pages/Project/Project.js').then(
          (module) => module.Project,
        ),
      {
        ssr: false,
      },
    ),
    Service: dynamic(
      async () =>
        await import('./tokyo/pages/Service/Service.js').then(
          (module) => module.Service,
        ),
      {
        ssr: false,
      },
    ),
  },
};
