import { useTemplate } from '@/hooks/useTemplate.js';
import { logger } from '@/services/logger.js';
import { templateRegistry } from '@/templates/templateRegistry.js';
import {
  type TemplateNames,
  type ThemeNames,
} from '@/templates/templateTypes.js';
import { TemplatePage } from '@/templates/types.js';

type TemplateRendererProps = {
  readonly className?: string;
  readonly data?: unknown;
  readonly page: TemplatePage;
  readonly templateOverride?: TemplateNames;
  readonly themeOverride?: ThemeNames;
};

const log = logger.child({ namespace: 'TemplateRenderer' });

export const TemplateRenderer = ({
  page,
  templateOverride,
  themeOverride,
  ...nextProps
}: TemplateRendererProps) => {
  const { current: templateName } = useTemplate();

  const resolvedTemplateName = templateOverride ?? templateName;
  const TemplateComponent = templateRegistry?.[resolvedTemplateName]?.[page];

  if (!TemplateComponent) {
    log.warn(`Failed to load template: ${resolvedTemplateName}:${page}`);
    return <h1>Error occurred</h1>;
  }

  return (
    <TemplateComponent
      templateOverride={templateOverride}
      themeOverride={themeOverride}
      {...nextProps}
    />
  );
};
