/* eslint-disable canonical/filename-match-exported */

import HomeQuery, {
  type HomePageQuery,
} from '@/__generated__/relay/HomePageQuery.graphql.js';
import { MiniInquiryButton } from '@/components/InquiryButton/MiniInquiryButton.js';
import { MissingResource } from '@/components/MissingResource/MissingResource.js';
import { PageHeadFragment } from '@/components/PageHead/PageHead.js';
import { TemplateRenderer } from '@/components/TemplateRenderer/TemplateRenderer.js';
import HOME_PAGE_QUERY from '@/documents/queries/HomePage.js';
import { getPreloadedQuery } from '@/services/relay/network.js';
import { isPlaywrightTestEnvironment } from '@/utilities/isPlaywrightTestEnvironment.js';
import { redirectInvalidUsername } from '@/utilities/redirectInvalidUsername.js';
import { resolveGraphQLResponse } from '@/utilities/resolveGraphQLResponse.js';
import { resolveMetadaUrl } from '@/utilities/resolveMetadataUrl.js';
import { resolveUsername } from '@/utilities/resolveUsername.js';
import { type GetServerSidePropsContext } from 'next';
import dynamic from 'next/dynamic';
import { type PreloadedQuery, usePreloadedQuery } from 'react-relay';

const PageProviders = dynamic(
  async () => await import('@/components/PageProviders/PageProviders.js'),
  {
    ssr: false,
  },
);

const Home = ({
  isCustomDomain,
  isPlaywright,
  queryRefs,
  ...props
}: {
  readonly isCustomDomain: boolean;
  readonly isPlaywright: boolean;
  readonly queryRefs: { query: PreloadedQuery<HomePageQuery> };
  readonly username: string;
}) => {
  const queryRef = usePreloadedQuery<HomePageQuery>(
    HOME_PAGE_QUERY,
    queryRefs.query,
  );

  if (!queryRef?.userProfileByUsername) {
    return <MissingResource />;
  }

  const { userProfileByUsername } = queryRef;
  const templateProps = { ...props, queryRef: userProfileByUsername };

  return userProfileByUsername ? (
    <>
      <PageHeadFragment
        isCustomDomain={isCustomDomain}
        queryNode={queryRef}
      />
      <PageProviders
        analyticsResourceData={null}
        isPlaywright={isPlaywright}
        templateQueryRef={queryRef}
        userQueryRef={queryRef.userProfileByUsername}
        {...props}
      >
        <TemplateRenderer
          page="Home"
          {...templateProps}
        />
        <MiniInquiryButton />
      </PageProviders>
    </>
  ) : (
    <MissingResource />
  );
};

export const getServerSideProps = async (
  context: GetServerSidePropsContext,
) => {
  const isPlaywright = isPlaywrightTestEnvironment(context);
  const { isCustomDomain, username } = await resolveUsername(context);

  if (!username) {
    return redirectInvalidUsername(context);
  }

  const pageUrl = resolveMetadaUrl(context, username);
  const pageQuery = await getPreloadedQuery(HomeQuery, {
    url: pageUrl,
    username,
  });

  const queryData = resolveGraphQLResponse(pageQuery.response);
  if (!queryData.data?.['userProfileByUsername']) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      isCustomDomain,
      isPlaywright,
      preloadedQueries: {
        query: pageQuery,
      },
      username,
    },
  };
};

export default Home;
