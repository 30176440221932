import { animated } from '@/components/animated/animated.js';
import { InquiryButton } from '@/components/InquiryButton/InquiryButton.js';
import { useInquiry } from '@/hooks/useInquiry.js';
import { useIsPage } from '@/hooks/useIsPage.js';
import { useMatchMedia } from '@/hooks/useMatchMedia.js';
import { styled } from '@/stitches/index.js';
import { minWidthMediaQueries } from '@/stitches/media.js';
import { pxToRem } from '@/utilities/pxToRem.js';
import { AnimatePresence, useMotionValueEvent, useScroll } from 'framer-motion';
import { useState } from 'react';

const Container = styled(animated.div, {
  alignItems: 'center',
  backgroundColor: '$pageBackground',
  borderTop: '$sizes$1 solid $gray30',
  bottom: 0,
  boxShadow: '$toast',
  display: 'flex',
  flexFlow: 'row nowrap',
  gap: '$10',
  justifyContent: 'space-between',
  left: 0,
  padding: `${pxToRem(7.5)} $14`,
  position: 'fixed',
  width: '100vw',
  zIndex: 1,
});

const MiniInquiryButtonCTA = () => {
  const { isServicePage } = useIsPage();
  const { userProfileIsReceivingInquiries } = useInquiry();

  if (isServicePage || !userProfileIsReceivingInquiries) {
    return null;
  }

  return (
    <Container
      animate={{ y: 0 }}
      exit={{ y: 100 }}
      initial={{ y: 100 }}
      key="mini-inquiry-button"
      transition={{ bounce: 0, type: 'spring' }}
    >
      <InquiryButton
        css={{
          width: '100%',
        }}
        section="mobile_footer"
        solidBg
      >
        Work With Me
      </InquiryButton>
    </Container>
  );
};

const SCROLL_THRESHOLD = 385;

export const MiniInquiryButton = () => {
  const isLargeScreen = useMatchMedia(minWidthMediaQueries.bp2);
  const { scrollY } = useScroll();
  const [isVisible, setIsVisible] = useState(false);

  useMotionValueEvent(scrollY, 'change', (progress) => {
    if (progress > SCROLL_THRESHOLD) {
      // Avoid unnecessary re-renders
      if (isVisible === false) {
        setIsVisible(true);
      }
    } else if (progress < SCROLL_THRESHOLD && isVisible === true) {
      setIsVisible(false);
    }
  });

  return (
    <AnimatePresence>
      {isVisible && !isLargeScreen ? <MiniInquiryButtonCTA /> : null}
    </AnimatePresence>
  );
};
